/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

import * as React from "react";
/* ---------------------------------  Packages ------------------------------ */
// import PropTypes from "prop-types";
// import Helmet from "react-helmet";
import styled from "styled-components";
import { getpageLang } from "../shared/extFunctions";
// import { Link } from "gatsby";

import jsonData from "../shared/json/projects.json";

/* -------------------------------  Import pages ---------------------------- */
import Gallery from "./gallery";
/* -------------------------------  Local Images ---------------------------- */

/* -------------------------------------------------------------------------- */
/*                                Main Content                                */
/* -------------------------------------------------------------------------- */

function Project({ data }) {
	const lang = getpageLang(data.lang);
	const projectName = data.project;
	const content =
		lang === "en" ? jsonData.en[projectName] : jsonData.fr[projectName];
	const ProjectContainer = styled.section`
		/* background-color: blue; */
		position: relative;
		margin: 15vh 0;
		width: 100vw;
		height: 60vh;
		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100vw;
			height: 100%;
			background-image: url(${content.background});
			/* background-repeat: no-repeat; */
			background-size: auto 100%;
			background-position: left;
			z-index: -1;
			/* filter: blur(2px); */
		}
		@media screen and ((max-device-width: 850px) or (max-width: 850px)) {
			&::before {
				/* filter: blur(1px); */
			}
		}
	`;

	return (
		<>
			<ProjectContainer id={projectName}>
				<SubContainer>
					<div className="logoContainer">
						<img
							src={content.image}
							alt={"logo " + content.title}
							className="logo"
						/>
					</div>
					<div className="textContainer ">
						<h1 className="title robotoFont">{content.title}</h1>
						<p className="desc robotoFont fancyScroll">{content.desc}</p>

						<img
							src={content.image2}
							alt="accent image"
							className={"accent " + projectName}
						/>
					</div>
				</SubContainer>
                <Sep className={projectName.length > 6 }>
                    <div className="one"></div>
                    <div className="two"></div>
                </Sep>
                {
                    projectName==="boublab" && <Sep1 className="three"/>
                }
			</ProjectContainer>
		</>
	);
}
export default Project;
/* ---------------------------- Styled Components --------------------------- */

/* -------------------------------------------------------------------------- */
/*                             External Functions                             */
/* -------------------------------------------------------------------------- */
const SubContainer = styled.div`
	width: 100vw;
	height: 60vh;
	/* padding-left:5vw; */
	display: grid;
	grid-template-columns: 1fr 2fr;
	position: relative;
    background-color: #ffffffa0;
	.logoContainer {
		width: 30vw;
		height: 60vh;
		display: flex;
		justify-content: center;
		align-items: center;
		.logo {
			object-fit: contain;
			width: 20vw;
		}
	}
	.textContainer {
		height: 60vh;
		position: relative;
		padding-top: 2rem;
		padding-right: 5rem;
		.accent {
			position: absolute;
			top: 2rem;
			right: 2rem;
			z-index: 0;
			height: 10rem;
			max-width: 15vw;
			object-fit: contain;
			&.freesh {
				position: absolute;
				top: 2rem;
				right: 2rem;
				z-index: 0;
				height: 7rem;
				max-width: 15vw;
				object-fit: contain;
			}
		}
		.title {
			font-size: 3rem;
			font-weight: 700;
			margin: 4rem 0;
		}
		.desc {
			margin: 1rem 0;
			font-size: 1.5rem;
			height: calc(60vh - 14rem);
			overflow-y: auto;
		}
	}
	@media screen and ((max-device-width: 850px) or (max-width: 850px)) {
		grid-template-columns: 1fr;
		grid-template-rows: 1fr 2fr;
		.logoContainer {
			height: 20vh;
			width: 100vw;
			.logo {
				height: 18vh;
				width: auto;
			}
		}
		.textContainer {
			padding: 0;
			width: 96vw;
			text-align: center;
			padding: 0 2vw;
			position: static;
			.accent {
				top: 2vw;
				right: 2vw;
				z-index: 0;
				height: auto;
				max-width: 25vw;
				object-fit: contain;
				&.freesh {
					top: 2vw;
					right: 2vw;
					max-width: 15vw;
					height: auto;
				}
				&.boubli {
					opacity: 0.7;
					transform-origin: top left;
					transform: rotateZ(-90deg);
					left: 1vw;
					top: 27vw;
				}
				&.boublab,
				&.boublicracy {
					opacity: 0.7;
				}
			}
			.title {
				font-size: 2rem;
				margin-bottom: 1rem;
				margin-top: 1.5rem;
			}
			.desc {
				font-size: 1rem;
				margin: 0;
			}
		}
	}
`;

const Sep = styled.div`
	width: 100vw;
	height: 15vh;
	/* background-color: red; */
	position: absolute;
	top: -15vh;
	left: 0;
    .one{
        width: 60vw;
        height:10vh;
        border-top: 4px solid black;
        border-left: 4px solid black;
        position:absolute;
        top:0;
        right:0;
    }
    .two{
        width: 50vw;
        height:4px;
        background-color:black;
        position:absolute;
        bottom:0;
        left:0;
    }

    &.true{
        /* background-color:blue; */
        .one{
            width: 4px;
            height:35vh;
            background-color: black;
            position:absolute;
            border: none;
            top:-10vh;
            left:13vw;
        }
        .two{
            width: 60vw;
            height:4px;
            background-color:black;
            position:absolute;
            bottom:0;
            left:40vw;
        }
        @media screen and ((max-device-width: 850px) or (max-width: 850px)) {
            .one{
                display:none;
            }
            .two{
                width: 50vw;
                height:12vh;
                background-color: transparent;
                border-bottom: 4px solid #000;
                border-left: 4px solid #000;
                position:absolute;
                bottom:0;
                left:50vw;
            }
        }
    }

`;
const Sep1 = styled.div`    
    width: 50vw;
    height:4px;
    background-color:black;
    position:absolute;
    bottom:0;
    left:0;
    @media screen and ((max-device-width: 850px) or (max-width: 850px)) {
        width:60vw;
    }
`;