/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

import * as React from "react";
/* ---------------------------------  Packages ------------------------------ */
// import PropTypes from "prop-types";
// import Helmet from "react-helmet";
import styled from "styled-components";
import { getpageLang } from "../shared/extFunctions";
// import { Link } from "gatsby";

import jsonData from "../shared/json/projects.json";

/* -------------------------------  Import pages ---------------------------- */
import Gallery from "./gallery";
/* -------------------------------  Local Images ---------------------------- */

/* -------------------------------------------------------------------------- */
/*                                Main Content                                */
/* -------------------------------------------------------------------------- */

function Portfolio({data}) {
    const lang = getpageLang(data.lang);
    const content = lang === "en" ? jsonData.en.portfolio : jsonData.fr.portfolio;
    return (
    <>
        <PortfolioContainer>
            <div className="rtoL diag"></div>
            <div className="lToR diag"></div>
            
            <h1 id="title" className="gothicFont" >{content.title}
                <div className="titleUnderline"></div>
            </h1>
            <Gallery data={content.items}/>
            
        </PortfolioContainer>        
    </>
    );
}
export default Portfolio;
/* ---------------------------- Styled Components --------------------------- */
const PortfolioContainer = styled.section` 
    /* background-color: blue; */
    position: relative;
    margin-top:15vh;
    margin-bottom: 35vh;
    width:90vw;
    height:fit-content;
    /* min-height: calc( 25vw / 4 * 3  * 4); */
    padding-left:5vw;
    /* overflow-y: hidden;
    overflow-x: visible; */
    #title{
        font-size:3rem;
        font-weight: bold;
        margin:0;
        width:fit-content;
    }
    .titleUnderline{
        background-color: #fff;
        box-shadow: -1.5px 1.5px 0 1.5px #000;
        width:150%;
        height:1rem;
        max-width:90vw;
    }
    .diag{
        height:150vw;
        width:25vw;
        position:absolute;
        background-color:#E1D8CC80;
        z-index:-1;

        left:40vw;
        top:-40vh;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        &.lToR{
            transform:rotateZ(-65deg);
            offset-anchor: left top ;
        }
        &.rtoL{
            transform:rotateZ(65deg);
            offset-anchor: left top ;
        }
        @media screen and ((max-device-width: 850px) or (max-width: 850px)) {
            top:0;
            transform: none;

        }
    }
    @media screen and ((max-device-width: 850px) or (max-width: 850px)) {
        /* min-height: calc( 25vw / 4 * 3  * 3); */
    }

`; 

/* -------------------------------------------------------------------------- */
/*                             External Functions                             */
/* -------------------------------------------------------------------------- */