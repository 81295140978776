/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

import * as React from "react";
/* ---------------------------------  Packages ------------------------------ */
// import PropTypes from "prop-types";
// import Helmet from "react-helmet";
import styled from "styled-components";
import Img from "gatsby-image";
import 'lazysizes';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
// import { Link } from "gatsby";
// import {blurryImageLoad } from "blurry-image-load";



/* -------------------------------  Import pages ---------------------------- */
/* -------------------------------  Local Images ---------------------------- */
import video from "../images/video32.svg"
import image from "../images/image32zoom.svg"
import imageunz from "../images/image32unzoom.svg"

/* -------------------------------------------------------------------------- */
/*                                Main Content                                */
/* -------------------------------------------------------------------------- */

function Gallery({data}) {
    
    if (typeof document !== "undefined") {
            
        setTimeout(() => {
            
        }, 200);
    } else {
        console.warn(
            "papish famma mochkla f document/window variable",
            "LandingContent"
        );
    }
    return (
    <>
        <GalleryContainer>            
            {
                data.map((item)=>{
                    if (item.type!=="video") return(
                        <div key={item.type+Math.random(5000)} className="itemContainer">
                            <div className={"imgContainer type"+item.type}>
                                <Zoom>

                                    <img src={item.srcblur} data-src={item.src} alt={""} className="image lazyload"/>
                                </Zoom>
                            </div>
                            <div className={"corner "+item.corner}></div>
                        </div>

                    )
                    return(
                        <div key={item.type+Math.random(5000)} className="itemContainer">
                            <div className={"imgContainer type"+item.type}>
                                <a href={item.url} target="_blank" norefer >
                                    <img src={item.srcblur} data-src={item.src} alt={""} className="image lazyload"/>
                                </a>
                            </div>
                            <div className={"corner "+item.corner}></div>
                        </div>

                    )
                })
            }
        </GalleryContainer>        
    </>
    );
}
export default Gallery;
/* ---------------------------- Styled Components --------------------------- */
const GalleryContainer = styled.section` 
    margin-top:5vh;
    columns: 3;

    .imgContainer{
        overflow:hidden;
        width:25vw;
        position:relative;
        .image{
            width:25vw;
            margin-bottom:2rem;
            transition: all .5s;
            object-fit:cover;
            &:hover{
                filter:brightness(.75) blur(1px);
                transform: scale(1.05);
            }
        }
        
    }
    .typeimage{
        /* transform: scale(3); */
        visibility: hidden;
        img{

            cursor: url(${image}), zoom-in !important;
        }
    }
    .typevideo{
        a{
            cursor:  url(${video}), grab ;

            /* cursor:  row-resize !important; */
        }
    }
    .itemContainer{
        position:relative;
        width: fit-content;
    }
    .corner {
        display:none;
        position: absolute;

        width:4rem;        
        height:4rem;
        box-shadow: 2px -2px 0 1.5px #000;
        &.mobBotLeft, &.mobTopLeft, &.mobTopRight{
            display:none;
        }
        &.deskTopLeft{
            display: block;
            top:0;
            left:-15px;
            
            transform:translateY(-15px) scaleX(-1);            
        }
        &.deskTopRight{
            display: block;
            top:0;
            right:-15px;
            
            transform:translateY(-15px) ;            
        }
        &.deskBotLeft{
            display: block;
            bottom:0;
            left:-15px;
            
            transform:translateY(-15px) scale(-1) ;            
        }
    }
    @media screen and ((max-device-width: 850px) or (max-width: 850px)) {
        columns:2;
        .imgContainer{
            width:40vw;
            .image{
                width:40vw;
                margin-bottom:1.25rem;
                &:hover{
                    filter: none;
                    transform: none;
                }
            }
        }
        .corner{
            width:2rem;
            height:2rem;
            &.deskTopLeft, &.deskBotLeft, &.deskTopRight{
                display:none;
            }
            &.mobTopLeft{
                display: block;
                top:0;
                left:-10px;
                
                transform:translateY(-10px) scaleX(-1);            
            }
            &.mobTopRight{
                display: block;
                top:0;
                right:-10px;
                
                transform:translateY(-10px) ;            
            }
            &.mobBotLeft{
                display: block;
                bottom:0;
                left:-10px;
                
                transform:translateY(-10px) scale(-1) ;            
            }
        }
    }
`; 

/* -------------------------------------------------------------------------- */
/*                             External Functions                             */
/* -------------------------------------------------------------------------- */
