/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

/* ---------------------------------  Packages ------------------------------ */
import * as React from "react";
// import Helmet from "react-helmet";
import styled from "styled-components";


/* -------------------------  Import pages/ components ---------------------- */
import Header from "../components/header.js"
import Footer from "../components/footer.js";
import { SEO } from "../components/seo.jsx";
import FloatingSocials from "../LandingPageComponents/floatingSocials.js";
import Portfolio from "../projectsComponents/portfolio.js";
import Project from "../projectsComponents/project.js";

import "./style.scss";
/* -------------------------------  Local Images ---------------------------- */
import minLogo from "../shared/A.png";

/* -------------------------------------------------------------------------- */
/*                                Main Content                                */
/* -------------------------------------------------------------------------- */

function Projects({location, data}) {  
  const params = new URLSearchParams(location.search);
  const lang= params.get("lang");
  //TODO: better image loading ✔
  //      background for portfolio
  //      mouse trailer
  //      image enlarge
  //      maybe video embed
  //      remind yosr to change web corners
    return (
      <>
        
        <Header data={{lang: lang}} />
        <Portfolio data={{lang: lang}} />
        
        <Project data={{lang:lang, project:"bo3"}} />
        <Project data={{lang:lang, project:"freesh"}} />
        <Project data={{lang:lang, project:"boubli"}} />
        <Project data={{lang:lang, project:"boublicracy"}} />
        <Project data={{lang:lang, project:"boublab"}} />

        <Footer data={{lang:lang}} />
        <FloatingSocials data={{lang:lang}} />
      </>
  );
}
export default Projects;

export const Head = () => (
  <>
      
      <link rel="icon" type="image/x-icon" href={minLogo}/>
      <meta name="viewport" content="width=device-width, initial-scale=1"/>
      <SEO title="Projects - ATDCE"/>
  </>
);
/* ---------------------------- Styled Components --------------------------- */
const Filler= styled.div`
width: 1px;
height: 250vh;
`;

/* -------------------------------------------------------------------------- */
/*                             External Functions                             */
/* -------------------------------------------------------------------------- */